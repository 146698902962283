<template>
  <span>
    <span
      :id="popoverId"
      class="fa fa-info-circle"
    />
    <b-popover
      :target="popoverId"
      :container="popoverId"
      triggers="hover focus"
      placement="top"
    >
      <div>
        <div class="content">
          <div class="title">
            {{ $t(description) }}
          </div>
        </div>
      </div>
    </b-popover>
  </span>
</template>
<script>
  export default {
    props: {
      popoverId: {
        type: String,
        default: 'popoverId'
      },
      description: {
        type: String,
        default: 'components.form.subscriptions.new.network-info'
      }
    }
  }
</script>
